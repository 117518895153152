import { useCallback, useEffect } from "react"
import dynamic from "next/dynamic"
import cn from "clsx"

import { useGallery } from "@app/contexts"
import { Empty } from "@app/features/favorites/components"
import { ActionBar } from "@app/features/masonry/components"
import { PhotoPreview } from "@app/features/masonry/components/photo-card/photo-preview"
import { PhotoCarousel } from "@app/features/photo-carousel/components"
import { useGetAllPhotos, usePhotoCarousel, useSetAllPhotos } from "@app/features/photo-carousel/store/hooks"
import { ProductsContainer } from "@app/features/products/components/products-container"
import { ProductsSection } from "@app/features/products/components/products-section"
import { Container } from "@app/ui/container"
import { scrollPhotoToView } from "@app/utils"

import { CommentButton } from "../comment-button"
import { CompleteSelection } from "../complete-selection"
const Masonry = dynamic(() => import("@app/features/masonry/components/masonry/masonry").then((mod) => mod.Masonry), {
  ssr: false,
})

import styles from "./favorites.module.scss"

const Favorites = ({
  favoritesListId,
  isDownloadEnabled,
  completedSelection,
  favoritesPhotos,
  isFavoritesSectionActivated,
}) => {
  const $setAllPhotos = useSetAllPhotos()
  const { gallery } = useGallery()

  const isShowProductSection = gallery?.photoCount >= 20 && gallery.isPrintableProductsEnabled

  useEffect(() => {
    if (favoritesPhotos) {
      $setAllPhotos(favoritesPhotos)
    }
  }, [favoritesPhotos, $setAllPhotos])

  const { $isPhotoCarouselVisible, $showPhotoCarousel, $setActivePhoto, $activePhoto, $setStartPhoto, $startPhoto } =
    usePhotoCarousel()
  const $allMediaFiles = useGetAllPhotos()

  const handleMediaFilePreviewClick = useCallback(
    (mediaFile) => {
      const mediaFileIdex = $allMediaFiles.findIndex((item) => item.id === mediaFile.id)
      $setActivePhoto(mediaFileIdex)
      $setStartPhoto(mediaFileIdex)
      $showPhotoCarousel()
    },
    [$allMediaFiles, $setActivePhoto, $showPhotoCarousel]
  )

  const isPhotosExist = !!favoritesPhotos?.length

  useEffect(() => {
    if (!$isPhotoCarouselVisible && $activePhoto && $activePhoto?.id !== $startPhoto?.id) {
      scrollPhotoToView($activePhoto?.id)
    }
  }, [$isPhotoCarouselVisible, $activePhoto, $startPhoto, scrollPhotoToView])

  return (
    <>
      <div
        className={cn(styles["root"], {
          [styles["grid"]]: isPhotosExist,
          [styles["empty"]]: !isPhotosExist,
        })}
      >
        {isPhotosExist && (
          <Container isFullWidth={true} className={styles["container"]}>
            <Masonry
              items={favoritesPhotos}
              // @ts-expect-error TODO: разобратся с типами
              getItemId={(item) => item.id}
              renderItem={(item) => (
                <PhotoPreview
                  isCursorPointer
                  // @ts-expect-error
                  photo={item}
                  onClick={() => handleMediaFilePreviewClick(item)}
                  renderOverlay={({ isHovered, photo: item, srcImage }) => (
                    <ActionBar
                      // @ts-expect-error
                      photo={item}
                      visible={isHovered}
                      srcImage={srcImage}
                      isCanBeHidden={false}
                      isDownloadEnabled={isDownloadEnabled}
                      favoritesListId={favoritesListId}
                      isPrintEnabled={gallery.isPrintableProductsEnabled}
                      isFavoritesSectionActivated={isFavoritesSectionActivated}
                      galleryId={gallery.id}
                    />
                  )}
                />
              )}
            />
            {completedSelection && <CommentButton />}
            {!completedSelection && <CompleteSelection galleryId={gallery.id} />}
          </Container>
        )}
        {isPhotosExist && (
          <PhotoCarousel type="favorites" isDownloadEnabled={isDownloadEnabled} isCanBeHidden={false} />
        )}

        {!isPhotosExist && <Empty />}
      </div>
      {isShowProductSection && (
        <ProductsContainer>
          <ProductsSection onlyAvailable={false} position={"footer"} />
        </ProductsContainer>
      )}
    </>
  )
}

export { Favorites }
