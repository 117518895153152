import React from "react"
import { useRouter } from "next/router"
import { useI18n } from "next-localization"

import { useGetFavoritesList } from "@app/api/favorites/queries"
import { useGallery } from "@app/contexts"
import { useLimitModal } from "@app/features/user/stores"
import { Button } from "ui/button"
import { Modal } from "ui/modal"

import styles from "./limit-modal.module.scss"

function LimitModal() {
  const router = useRouter()
  const { isLimitModalVisible, hideLimitModal } = useLimitModal()
  const { gallery } = useGallery()
  const { data: favoritesList } = useGetFavoritesList(gallery.id, { query: { enabled: false } })
  const limitPhoto = gallery?.favListPhotoLimit
  const redirectToDefaultFavoritesListPage = (id: string) => {
    if (id) {
      router.push(`/favorites-list/${id}`)
    }
  }

  function handleClickButton() {
    redirectToDefaultFavoritesListPage(favoritesList.data.id)
    hideLimitModal()
  }

  const i18n = useI18n()

  return (
    <Modal open={isLimitModalVisible} onClose={hideLimitModal} isMobileType={true}>
      <div className={styles["root"]}>
        <p className={styles["text"]}>{i18n.t("favoritesModal.description", { limitPhoto })}</p>
        <Button skin="secondary" onClick={handleClickButton} className={styles["button"]}>
          {i18n.t("favoritesModal.button")}
        </Button>
      </div>
    </Modal>
  )
}

export { LimitModal }
