import type { FC } from "react"

import { useGlobalState } from "@app/contexts"
import { OnboardingTour } from "@app/features/onboarding/components/onboarding-tour"
import { IS_FIRST_VISIT_LOCAL_STORAGE_KEY, STEPS_CLASS_NAME_TOUR } from "@app/features/onboarding/constants"
import { CenterElipsis } from "@app/ui/center-elipsis"
import { CarouselEngine, Header, HeaderVideo } from "features/photo-carousel/components"
import { usePhotoCarousel } from "features/photo-carousel/store/hooks"

import styles from "./photo-carousel.module.scss"

type Props = {
  type?: string
  isDownloadEnabled: boolean
  isCanBeHidden: boolean
}

const PhotoCarousel: FC<Props> = ({ type, isDownloadEnabled, isCanBeHidden }) => {
  const { isFavoritesSectionActivated } = useGlobalState()
  const { $isPhotoCarouselVisible, $activePhoto } = usePhotoCarousel()
  const mediaFileType = $activePhoto?.type

  const stepsClassNameTour = []
  if (isDownloadEnabled) {
    stepsClassNameTour.push(STEPS_CLASS_NAME_TOUR.download.name)
  }

  stepsClassNameTour.push(STEPS_CLASS_NAME_TOUR.share.name)

  if (isFavoritesSectionActivated) {
    stepsClassNameTour.push(STEPS_CLASS_NAME_TOUR.like.name)
  }

  if ($isPhotoCarouselVisible && $activePhoto) {
    return (
      <OnboardingTour stepsClassName={stepsClassNameTour} localStorageKey={IS_FIRST_VISIT_LOCAL_STORAGE_KEY}>
        <aside className={styles["root"]}>
          {mediaFileType === "photo" && (
            <Header
              type={type}
              isDownloadEnabled={isDownloadEnabled}
              isActionsEnabled={$activePhoto?.photos}
              isCanBeHidden={isCanBeHidden}
              className={styles["header"]}
            />
          )}
          {mediaFileType === "video" && (
            <HeaderVideo
              isDownloadEnabled={isDownloadEnabled}
              isCanBeHidden={isCanBeHidden}
              isActionsEnabled={$activePhoto?.fileKey}
              className={styles["header"]}
            />
          )}
          <CarouselEngine isDownloadEnabled={isDownloadEnabled} />
          <p className={styles["name"]}>{$activePhoto.name}</p>
          <p className={styles["name-mobile"]}>
            <CenterElipsis beforeCharacters={12} afterCharacters={12} text={$activePhoto.name} />
          </p>
        </aside>
      </OnboardingTour>
    )
  }

  return null
}

export { PhotoCarousel }
