import { type FC, useEffect } from "react"
import { useI18n } from "next-localization"
import clsx from "clsx"

import { QA_CLASS_NAMES } from "@app/constants"
import { STEPS_CLASS_NAME_TOUR } from "@app/features/onboarding/constants"
import { IconWrapper } from "@app/features/theme/icon-wrapper"
import { Like } from "@app/ui/icons"
import { Tooltip } from "@app/ui/tooltip"
import { usePhotoLike } from "@app/utils/hooks/favorites/use-photo-like"

import styles from "./action-like.module.scss"

type Props = {
  activePhoto: any
  imageUrl: string
  galleryId: string
  isFavoritesPage: boolean
}

const ActionLike: FC<Props> = ({ activePhoto, imageUrl, galleryId, isFavoritesPage }) => {
  const { t } = useI18n()
  const liked = (activePhoto.photos && activePhoto.isLiked) || (isFavoritesPage && activePhoto.photos)
  const { handleLike, handleUnlike, isLiked, setIsLiked } = usePhotoLike(galleryId, liked)
  useEffect(() => {
    setIsLiked(activePhoto.isLiked || isFavoritesPage)
  }, [activePhoto, isFavoritesPage, setIsLiked])

  const handleClick = !isLiked
    ? (e: React.MouseEvent) => handleLike(e, activePhoto.id, imageUrl)
    : (e: React.MouseEvent) => handleUnlike(e, activePhoto.id)

  return (
    <div className={styles["action"]}>
      <Tooltip text={isLiked ? t("tooltip.removeFromFavorites") : t("tooltip.addToFavorites")}>
        <button
          className={clsx(
            styles["button"],
            styles["like-button"],
            STEPS_CLASS_NAME_TOUR.like.name,
            { [QA_CLASS_NAMES.fullSize.favourites]: !isLiked },
            { [QA_CLASS_NAMES.fullSize.outFavourites]: isLiked }
          )}
          onClick={handleClick}
        >
          <IconWrapper
            light={
              <Like width="18" height="16" className={clsx(isLiked && styles["liked"])} stroke="#000" fill="none" />
            }
            dark={
              <Like width="18" height="16" className={clsx(isLiked && styles["liked"])} stroke="#fff" fill="none" />
            }
          />
        </button>
      </Tooltip>
    </div>
  )
}

export { ActionLike }
