import type { FC } from "react"
import clsx from "clsx"

import { QA_CLASS_NAMES } from "@app/constants"
import { HidingMediaFile } from "@app/features/hiding-media-files/components"

import styles from "./action-hide.module.scss"

type Props = {
  activePhoto: any
}

const ActionHide: FC<Props> = ({ activePhoto }) => {
  return (
    <div className={styles["hiding-action"]}>
      <HidingMediaFile
        mediaFileById={activePhoto}
        buttonClassName={clsx(styles["button"], QA_CLASS_NAMES.fullSize.hiding)}
        classNameIcon={styles["hidden-icon"]}
        tooltipPosition="down-left"
        hidingClassName={undefined}
        isMobile={undefined}
      />
    </div>
  )
}

export { ActionHide }
