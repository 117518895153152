import { FC } from "react"
import { useI18n } from "next-localization"
import clsx from "clsx"

import { QA_CLASS_NAMES } from "@app/constants"
import { trackYandexMetrikaAction } from "@app/features/analytics/utils"
import { STEPS_CLASS_NAME_TOUR } from "@app/features/onboarding/constants"
import { getPhotoDirectURL } from "@app/features/photo/utils"
import { useSharing } from "@app/features/sharing/hooks"
import { IconWrapper } from "@app/features/theme/icon-wrapper"
import { Share } from "@app/ui/icons"
import { Tooltip } from "@app/ui/tooltip"

import styles from "./action-share.module.scss"

type Props = {
  activePhoto: any
  isMobile: boolean
  imageUrl: string
}

const ActionShare: FC<Props> = ({ activePhoto, isMobile, imageUrl }) => {
  const { t } = useI18n()
  const share = useSharing()

  function handleShareClick() {
    trackYandexMetrikaAction(
      isMobile ? "click-share-photo-full-screen-mobile" : "click-share-photo-full-screen-desktop"
    )
    share("photo", {
      title: activePhoto.name,
      url: getPhotoDirectURL(activePhoto.id),
      fileURL: imageUrl,
    })
  }

  return (
    <div className={styles["action"]}>
      <Tooltip text={t("common.share")}>
        <button
          className={clsx(styles["button"], STEPS_CLASS_NAME_TOUR.share.name, QA_CLASS_NAMES.fullSize.share)}
          onClick={handleShareClick}
        >
          <IconWrapper
            light={<Share width="21" height="17" fill="#000" />}
            dark={<Share width="21" height="17" fill="#fff" />}
          />
        </button>
      </Tooltip>
    </div>
  )
}

export { ActionShare }
