import React from "react"
import { useRouter } from "next/router"
import { useI18n } from "next-localization"

import { useGlobalState } from "@app/contexts"
import { trackYandexMetrikaAction } from "@app/features/analytics/utils"
import { IconWrapper } from "@app/features/theme/icon-wrapper"
import { useDownloadFavoritePhotos } from "features/download/hooks"
import { Back } from "ui/back"
import { Container } from "ui/container"
import { Download, Picture } from "ui/icons"
import { Tooltip } from "ui/tooltip"

import styles from "./header.module.scss"

const Header = ({ favoritesListId, isDownloadEnabled, favoritesPhotosCount = 0 }) => {
  const {
    userAgent: { isMobile },
  } = useGlobalState()

  const { t } = useI18n()
  const router = useRouter()

  const downloadFavoritePhotos = useDownloadFavoritePhotos(favoritesListId)

  const handleGoBackClick = () => {
    const numberOfEntries = window.history.length
    const isPreviousPageExist = numberOfEntries > 1
    if (isPreviousPageExist) {
      router.back()
    } else {
      router.push("/")
    }
  }

  function handleDownloadFavoritePhotosClick() {
    trackYandexMetrikaAction(
      isMobile ? "click-favorites-download-photo-mobile" : "click-favorites-download-photo-desktop"
    )
    downloadFavoritePhotos()
  }

  return (
    <header className={styles["header"]}>
      <Container>
        <nav className={styles["navigation"]}>
          <Back text={t("common.back")} className={styles["back"]} page="favorites" onClick={handleGoBackClick}></Back>
          <ul className={styles["actions"]}>
            {isDownloadEnabled && (
              <li className={styles["action"]}>
                {!!favoritesPhotosCount && (
                  <Tooltip text={t("common.downloadAllPhotos")} position="down-right">
                    <button onClick={handleDownloadFavoritePhotosClick}>
                      <IconWrapper
                        light={<Download width="18" height="18" />}
                        dark={<Download stroke="#fff" width="18" height="18" />}
                      />
                    </button>
                  </Tooltip>
                )}
              </li>
            )}
          </ul>
        </nav>
        <h1 className={styles["title"]}>{t("common.favorites")}</h1>
        <p className={styles["count"]}>
          <span className={styles["mark-icon"]}>
            <IconWrapper
              light={<Picture width="12" height="9" />}
              dark={<Picture width="12" height="9" stroke="#929292" />}
            />
          </span>
          {favoritesPhotosCount}
        </p>
      </Container>
    </header>
  )
}

export { Header }
