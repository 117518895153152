import type { FC } from "react"
import { useI18n } from "next-localization"
import clsx from "clsx"

import { QA_CLASS_NAMES } from "@app/constants"
import { trackYandexMetrikaAction } from "@app/features/analytics/utils"
import { useDownloadPhoto } from "@app/features/download/hooks"
import { STEPS_CLASS_NAME_TOUR } from "@app/features/onboarding/constants"
import { IconWrapper } from "@app/features/theme/icon-wrapper"
import { Download } from "@app/ui/icons"
import { Tooltip } from "@app/ui/tooltip"

import styles from "./action-download.module.scss"

type Props = {
  isMobile: boolean
  activePhoto: any
}

const ActionDownload: FC<Props> = ({ isMobile, activePhoto }) => {
  const { t } = useI18n()
  const downloadPhoto = useDownloadPhoto(activePhoto.id, activePhoto.photos)

  const handleDownloadClick = () => {
    if (isMobile) {
      trackYandexMetrikaAction("click-download-photo-full-screen-mobile")
    }
    if (!isMobile) {
      trackYandexMetrikaAction("click-download-photo-full-screen-desktop")
    }
    downloadPhoto()
  }

  return (
    <div className={styles["action"]}>
      <Tooltip text={t("tooltip.downloadPhoto")}>
        <button
          className={clsx(styles["button"], STEPS_CLASS_NAME_TOUR.download.name, QA_CLASS_NAMES.fullSize.download)}
          onClick={handleDownloadClick}
        >
          <IconWrapper
            light={<Download stroke="#000" width="18" height="18" />}
            dark={<Download stroke="#fff" width="18" height="18" />}
          />
        </button>
      </Tooltip>
    </div>
  )
}

export { ActionDownload }
