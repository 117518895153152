import { type FC, useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { useI18n } from "next-localization"
import cx from "clsx"

import { useGetFavoritesList } from "@app/api/favorites/queries"
import { getFavoritesListKey } from "@app/api/favorites/query-keys"
import { QA_CLASS_NAMES } from "@app/constants"
import { useGlobalState } from "@app/contexts"
import { sendCommentForFavoritesList, updateFavoritesList } from "@app/features/favorites/requests"
import { useCommentsModal } from "@app/features/favorites/store"
import { useAuthorization } from "@app/features/user/stores"
import { Button } from "@app/ui/button"
import { SimpleModal } from "@app/ui/modal"
import { Textarea } from "@app/ui/textarea"

import styles from "./comment-modal.module.scss"

export type Props = { favoritesListId: string; galleryId: string }

const CommentModal: FC<Props> = ({ favoritesListId, galleryId }) => {
  const { isUserAuthorized } = useAuthorization()
  const { data: favoriteList } = useGetFavoritesList(galleryId, { query: { enabled: isUserAuthorized } })
  const i18n = useI18n()
  const queryClient = useQueryClient()
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [isSuccessfullySumbited, setIsSuccessfullySubmited] = useState(false)
  const [error, setError] = useState("")
  const errors = {
    empty: i18n.t("favoritesListPage.userCommentsModal.leaveACommentValidationMessage"),
    limit: i18n.t("favoritesListPage.userCommentsModal.leaveACommentValidationLimit"),
    default: i18n.t("favoritesListPage.userCommentsModal.leaveACommentValidationDefault"),
  }

  const { isCommentModalVisible, hideCommentModal } = useCommentsModal()
  const {
    userAgent: { isIos },
  } = useGlobalState()

  const isInitialSelectionCompleted = favoriteList?.data?.isInitialSelectionCompleted

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault()
    setIsSubmiting(true)
    const comment = event.target["elements"].comment.value.trim()

    if (isSubmiting) return

    if (!isInitialSelectionCompleted) {
      if (comment) {
        const response = await sendCommentForFavoritesList({ text: comment, favoritesListId })

        if (!response.success) {
          if (response.code === 400) {
            setError("limit")
          } else {
            setError("default")
          }

          setIsSubmiting(false)

          return
        }
      }

      await updateFavoritesList({
        key: "isInitialSelectionCompleted",
        value: true,
        favoritesListId,
      })

      queryClient.invalidateQueries({ queryKey: getFavoritesListKey(galleryId), exact: true })

      setIsSuccessfullySubmited(true)

      return
    } else if (isInitialSelectionCompleted && comment) {
      const response = await sendCommentForFavoritesList({ text: comment, favoritesListId })

      if (response.success) {
        setIsSuccessfullySubmited(true)
      } else {
        if (response.code === 400) {
          setError("limit")
        } else {
          setError("default")
        }

        setIsSubmiting(false)
      }

      return
    }

    if (isInitialSelectionCompleted && !comment) {
      setError("empty")
      setIsSubmiting(false)

      return
    }

    setIsSubmiting(false)
  }

  function handleCloseClick() {
    hideCommentModal()
    setIsSubmiting(false)
    setIsSuccessfullySubmited(false)
    setError("")
  }

  function handleFocus() {
    setError("")
  }

  return (
    <SimpleModal
      open={isCommentModalVisible}
      onClose={handleCloseClick}
      crossClassName={QA_CLASS_NAMES.favourites.feedbackClose}
    >
      <form onSubmit={handleSubmit} className={styles["form"]}>
        {!isSuccessfullySumbited && (
          <>
            <h3 className={styles["title"]}>
              {!isInitialSelectionCompleted && i18n.t("favoritesListPage.userCommentsModal.completeSelectionTitle")}
              {isInitialSelectionCompleted && i18n.t("favoritesListPage.userCommentsModal.leaveACommentTitle")}
            </h3>
            <p className={styles["description"]}>
              {!isInitialSelectionCompleted &&
                i18n.t("favoritesListPage.userCommentsModal.completeSelectionDescription")}
              {isInitialSelectionCompleted && i18n.t("favoritesListPage.userCommentsModal.leaveACommentDescription")}
            </p>
            <Textarea
              placeholder={i18n.t("favoritesListPage.userCommentsModal.completeSelectionPlaceholder")}
              className={cx(
                styles["textarea"],
                error && styles["error"],
                {
                  [styles["scalable-area"]]: isIos,
                },
                QA_CLASS_NAMES.favourites.input
              )}
              disabled={isSubmiting}
              name="comment"
              onFocus={handleFocus}
            ></Textarea>
            {error && <p className={styles["error-message"]}>{errors[error]}</p>}
            <Button
              type="submit"
              loading={isSubmiting}
              className={cx(styles["button"], QA_CLASS_NAMES.favourites.send)}
            >
              {i18n.t("common.send")}
            </Button>
          </>
        )}

        {isSuccessfullySumbited && (
          <>
            <h3 className={styles["title"]}>
              {!isInitialSelectionCompleted &&
                i18n.t("favoritesListPage.userCommentsModal.completeSelectionSuccessTitle")}
              {isInitialSelectionCompleted && i18n.t("favoritesListPage.userCommentsModal.leaveACommentSuccessTitle")}
            </h3>
            <p className={styles["description"]}>
              {!isInitialSelectionCompleted &&
                i18n.t("favoritesListPage.userCommentsModal.completeSelectionSuccessDescription")}
              {isInitialSelectionCompleted &&
                i18n.t("favoritesListPage.userCommentsModal.leaveACommentSuccessDescription")}
            </p>
          </>
        )}
      </form>
    </SimpleModal>
  )
}

export { CommentModal }
