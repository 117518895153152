import type { FC } from "react"
import { useI18n } from "next-localization"
import clsx from "clsx"

import { COLORS, QA_CLASS_NAMES } from "@app/constants"
import { OnboardingHint } from "@app/features/onboarding/components/onboarding-hint"
import { OnboardingPrintBlock } from "@app/features/onboarding/components/onboarding-print-block"
import { PRINT_ONBOARDING_PRINT_HINT } from "@app/features/onboarding/constants"
import { useShowProductsModal } from "@app/features/products/hooks/products-modal"
import { PRODUCTS_MODAL_TYPES } from "@app/features/products/types"
import { IconWrapper } from "@app/features/theme/icon-wrapper"
import { Printer } from "@app/ui/icons"
import { Tooltip } from "@app/ui/tooltip"

import styles from "./action-print.module.scss"

type Props = {
  isOnboardingPrintVisible: boolean
  cover: any
}

const ActionPrint: FC<Props> = ({ isOnboardingPrintVisible, cover }) => {
  const { t } = useI18n()
  const showProductsModal = useShowProductsModal()

  const printOnboardingText = "Вы можете заказать книгу \nс вашими фото."

  return (
    <div className={styles["action"]}>
      <Tooltip text={t("tooltip.printPhoto")}>
        <OnboardingHint
          canVisible={isOnboardingPrintVisible}
          visibleOnView={true}
          inViewMargin={0}
          isMobileFixed={true}
          isTransparentClickEnabled={true}
          onTransparentClick={() => showProductsModal(PRODUCTS_MODAL_TYPES.DEFAULT)}
          id={PRINT_ONBOARDING_PRINT_HINT}
          arrowPosition={{ desktop: "right-none", mobile: "none" }}
          offset="20px"
          space="0 -10px 0 0"
          type="circle"
          withImageType={true}
          isClickableContent={true}
          content={<OnboardingPrintBlock description={printOnboardingText} cover={cover} />}
        >
          <button
            className={clsx(styles["button"], QA_CLASS_NAMES.fullSize.print)}
            onClick={() => showProductsModal(PRODUCTS_MODAL_TYPES.DEFAULT)}
          >
            <IconWrapper light={<Printer />} dark={<Printer stroke={COLORS.white} />} />
          </button>
        </OnboardingHint>
      </Tooltip>
    </div>
  )
}

export { ActionPrint }
