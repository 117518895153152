import { useEffect } from "react"
import { useTour } from "@reactour/tour"
import { useI18n } from "next-localization"
import clsx from "clsx"

import { QA_CLASS_NAMES } from "@app/constants"
import { useGallery, useGlobalState } from "@app/contexts"
import { IS_FIRST_VISIT_LOCAL_STORAGE_KEY } from "@app/features/onboarding/constants"
import { useOnboarding } from "@app/features/onboarding/store/hooks"
import { checkOnboardingActivation } from "@app/features/onboarding/utils"
import { isFirstVisit } from "@app/features/onboarding/utils"
import { usePhotoCarousel } from "@app/features/photo-carousel/store/hooks"
import { IconWrapper } from "@app/features/theme/icon-wrapper"
import { Cross } from "@app/ui/icons"
import { useKeyPress } from "@app/utils"
import { useLinkImage } from "@app/utils/hooks"

import { ActionDownload } from "./action-download"
import { ActionHide } from "./action-hide"
import { ActionLike } from "./action-like"
import { ActionPrint } from "./action-print"
import { ActionShare } from "./action-share"

import styles from "./header.module.scss"

function Header({ type, isDownloadEnabled, isCanBeHidden, isActionsEnabled, className }) {
  const {
    isFavoritesSectionActivated,
    userAgent: { isMobile },
    imageProxyHost,
    size,
    imgFormat,
  } = useGlobalState()
  const { gallery } = useGallery()
  const { setIsOpen } = useTour()
  const { $isOnboardingPrintVisible, $hideOnboardingPrint, $showOnboardingTour } = useOnboarding()
  const { $hidePhotoCarousel, $activePhoto } = usePhotoCarousel()
  const photo_path = $activePhoto?.photos && $activePhoto?.photos[0]
  const [linkImage] = useLinkImage(size.md, photo_path, $activePhoto?.photos)

  const isPrintableProductsEnabled = gallery?.isPrintableProductsEnabled

  const isOnboardingEnabled = checkOnboardingActivation(gallery.settings)
  const isFirstVisitGallery = isFirstVisit(IS_FIRST_VISIT_LOCAL_STORAGE_KEY)

  const getSrcImage = (size, linkImage, imgFormat) => {
    const imageSizeFormat = `=w${size}${imgFormat}`

    return linkImage.includes(imageProxyHost) ? linkImage : `${linkImage}${imageSizeFormat}`
  }

  const srcImage = getSrcImage(size.xs, linkImage, imgFormat)
  const imageURL = srcImage

  useEffect(() => {
    if (isMobile && isOnboardingEnabled && isFirstVisitGallery) {
      $showOnboardingTour()
      $hideOnboardingPrint()
      setTimeout(() => {
        setIsOpen(true)
      }, 300)
    }
  }, [$activePhoto])

  const i18n = useI18n()

  useKeyPress("Escape", $hidePhotoCarousel)

  return (
    <header className={clsx(styles["root"], className)}>
      {isActionsEnabled && (
        <>
          {isPrintableProductsEnabled && (
            <ActionPrint isOnboardingPrintVisible={$isOnboardingPrintVisible} cover={gallery.cover.sm} />
          )}

          {isCanBeHidden && <ActionHide activePhoto={$activePhoto} />}

          {isDownloadEnabled && <ActionDownload isMobile={isMobile} activePhoto={$activePhoto} />}

          <ActionShare activePhoto={$activePhoto} isMobile={isMobile} imageUrl={imageURL} />

          {isFavoritesSectionActivated && (
            <ActionLike
              activePhoto={$activePhoto}
              imageUrl={imageURL}
              galleryId={gallery.id}
              isFavoritesPage={type === "favorites"}
            />
          )}
        </>
      )}
      <button
        className={clsx(styles["action"], styles["cross"], QA_CLASS_NAMES.fullSize.close)}
        onClick={$hidePhotoCarousel}
      >
        <IconWrapper
          light={<Cross width={16} height={16} stroke="#000" />}
          dark={<Cross width={16} height={16} stroke="#fff" />}
        />
      </button>
    </header>
  )
}

export { Header }
